import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedUiLoaderComponent } from '@enx/shared/ui/components';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedFeatureLoaderService {
  loadingState$ = new BehaviorSubject<string[]>([]);
  private spinnerDialogRef: MatDialogRef<SharedUiLoaderComponent> | null = null;

  constructor(private dialog: MatDialog) {}

  show(url: string): void {
    const areUrlsLoading = !!this.loadingState$.getValue().length;
    const newUrls = [...this.loadingState$.getValue(), url];

    this.loadingState$.next(newUrls);
    if (areUrlsLoading) return;

    this.spinnerDialogRef = this.dialog.open(SharedUiLoaderComponent, {
      disableClose: true,
      panelClass: 'enx-loader-dialog',
      backdropClass: 'enx-backdrop--loader',
    });
  }

  hide(url: string): void {
    const newUrls = this.loadingState$.getValue().filter((item) => item !== url);
    this.loadingState$.next(newUrls);
    const areUrlsLoading = !!newUrls.length;
    if (areUrlsLoading) return;

    this.spinnerDialogRef!.close();
    this.spinnerDialogRef = null;
  }
}
