import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { SharedUiSnackbarComponent } from '@enx/shared/ui/components';

@Injectable()
export class SharedFeatureSnackbarService {
  readonly snackbar = inject(MatSnackBar);

  openSuccessSnackbar(messages: string[]): MatSnackBarRef<SharedUiSnackbarComponent> {
    return this.openSnackbarFromComponent(SharedUiSnackbarComponent, {
      panelClass: ['enx-snackbar--success'],
      data: messages,
    });
  }

  openErrorSnackbar(messages: string[]): MatSnackBarRef<SharedUiSnackbarComponent> {
    return this.openSnackbarFromComponent(SharedUiSnackbarComponent, {
      panelClass: ['enx-snackbar--error'],
      data: messages,
    });
  }

  openWarningSnackbar(messages: string[]): MatSnackBarRef<SharedUiSnackbarComponent> {
    return this.openSnackbarFromComponent(SharedUiSnackbarComponent, {
      panelClass: ['enx-snackbar--warning'],
      data: messages,
    });
  }

  openSnackbarFromComponent<T, D>(component: ComponentType<T>, config?: MatSnackBarConfig<D>): MatSnackBarRef<T> {
    const panelClass = ['enx-snackbar', ...(config?.panelClass || [])];

    return this.snackbar.openFromComponent<T, D>(component, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      ...config,
      panelClass,
    });
  }
}
